import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import connexionServeur from "../Helpers/ConnexionServeur";
import Acheteurs from "./Acheteurs";
import ClientItem from "./ClientItem";
import Fournisseurs from "./Fournisseurs";

const Clients = ()=>{
    const [listeClients, setListeClients] = useState([])
    const [listeFournisseurs, setListeFournisseurs] = useState([])
    const [listeAcheteurs, setListeAcheteurs] = useState([])
    const [fournisseur, setFournisseur] = useState(true)
    const [recherche, setRecherche] = useState("")
    const [titre, setTitre] = useState("Fournisseurs")



    useEffect(() => {
        sendRecherche();
    },[recherche] )

    const tabClic = (e)=>{
        e.preventDefault()
        if(e.currentTarget.id == "fournisseurs")
        {
            setFournisseur(true)
            setTitre("Fournisseurs")
        }  
        else
        {
            setFournisseur(false)
            setTitre("Acheteurs")
        }
            
    }

    /* const toServeur =async ()=>{
        let response = await connexionServeur("clients/getClients", [])
        let data = await response.json()
        setListeClients(data.donnees)
    } */

    const sendRecherche =async ()=>{
        let donnees = new FormData()
        donnees.append("recherche", recherche)
        let response = await connexionServeur("clients/getClientByRecherche", donnees)
        let data = await response.json()
        if(data.resultat == true)
        {
            setListeAcheteurs(data.acheteurs)
            setListeFournisseurs(data.fournisseurs)
        }
        setListeClients(data.donnees)
    }

    const rechercheChange = (e)=>{
        setRecherche(e.target.value)
        //sendRecherche()
    }

    return(
        <div className="container">
            <div className="row ">
                <div className="col-12 col-md-6">
                    <h1 className="">{titre}</h1>
                </div>
                <div className="col-12 col-md-6 align-self-center text-end">
                    <div className="input-group">
                        <input type="text" className="form-control" onChange={rechercheChange} placeholder="nom ou téléphone" aria-label="recherche" aria-describedby="basic-addon1" />
                        <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faSearch} size="1x"/></span>
                    </div>
                </div>
            </div>
            <div className="container mt-4 p-3">
                <div className="card">
                    <div className="card-header">
                        <ul className="nav nav-tabs card-header-tabs">
                            <li className="nav-item">
                                <a className={fournisseur ? "nav-link active tabActif " : "nav-link tabInactif "} id="fournisseurs" onClick={tabClic} aria-current="true" href="#">Fournisseurs</a>
                            </li>
                            <li className="nav-item">
                                <a className={fournisseur ? "nav-link tabInactif " : "nav-link active tabActif "} id="acheteurs" onClick={tabClic}  href="#">Acheteurs</a>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {fournisseur ? <Fournisseurs listeFournisseurs={listeFournisseurs} /> : <Acheteurs listeAcheteurs={listeAcheteurs}/>}
                        </div>
                    </div>
                </div>
            </div>
        
            

            
        </div>
    )
}

export default Clients