import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import connexionServeur from "../Helpers/ConnexionServeur"
import ModalAjoutCategorie from "../Modals/ModalAjoutCategorie"
import ModalAjoutProduit from "../Modals/ModalAjoutProduit"
import ModalAjoutSousCategorie from "../Modals/ModalAjoutSousCategorie"
import ModalUpdatePhotoProduit from "../Modals/ModalUpdatePhotoProduit"
import ModalUpdatePhotoSousCategorie from "../Modals/ModalUpdatePhotoSousCategorie"
import ModalUpdateProduit from "../Modals/ModalUpdateProduit"
import ModalUpdateSousCategorie from "../Modals/ModalUpdateSousCategorie"
import CategorieItem from "./CategorieItem"
import ProduitItem from "./ProduitItem"
import SousCategorieItem from "./SousCategorieItem"

const Produits = ()=>{

    const [donnees, setDonnees] = useState([])
    const [currentProduit, setCurrentProduit] = useState(null)
    const [modalAjoutProduitOpen, setModalAjoutProduitOpen] = useState(false)
    const [modalUpdateProduitOpen, setModalUpdateProduitOpen] = useState(false)
    const [modalUpdatePhotoProduitOpen, setModalUpdatePhotoProduitOpen] = useState(false)
    const [retour, setRetour] = useState(false)

    useEffect(() => {
        toServeur()
      }, [retour])

    const clicBtAjoutProduit = (e)=>{
        setModalAjoutProduitOpen(true)
    }

    const openModalUpdateProduit = (index)=>{
        setCurrentProduit(donnees[index])
        setModalUpdateProduitOpen(true)
    }

    const openModalUpdatePhotoProduit = (index)=>{
        setCurrentProduit(donnees[index])
        setModalUpdatePhotoProduitOpen(true)
    }

    const fermerModal = (success)=>{
        setModalAjoutProduitOpen(false)
        setModalUpdateProduitOpen(false)
        setModalUpdatePhotoProduitOpen(false)
        if(success == true)
            setRetour(!retour)
    }

    const toServeur =async ()=>{
        let response = await connexionServeur("produits/getProduits", [])
        let data = await response.json()
        setDonnees(data.donnees)
    }

    return(
        <div className="container">
            <div className="row ">
                <div className="col">
                    <h1 className="">Produits</h1>
                </div>
                <div className="col align-self-center text-end">
                    <button className="btn btn-success" onClick={clicBtAjoutProduit}>
                        <FontAwesomeIcon icon={faPlus} /> <span className="d-none d-sm-inline"> Nouveau produit</span>   
                    </button> 
                </div>
            </div>
        
            <div className="row row-cols-1 row-cols-md-4 g-1">
                {
                    donnees.map((produit, index)=><ProduitItem key={produit.idProduit} produit={produit} index={index} ouvrir={openModalUpdateProduit} ouvrirModalPhoto={openModalUpdatePhotoProduit}/>)
                }
            </div>

            <ModalAjoutProduit afficher={modalAjoutProduitOpen} fermer={fermerModal} />
            <ModalUpdateProduit currentProduit={currentProduit} afficher={modalUpdateProduitOpen}  fermer={fermerModal} />
            <ModalUpdatePhotoProduit currentProduit={currentProduit} afficher={modalUpdatePhotoProduitOpen}  fermer={fermerModal} />

        </div>
    )
}

export default Produits