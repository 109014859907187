import { useEffect } from "react"
import ModalCarteClient from "../Modals/ModalCarteClient"
import OffreItem from "./OffreItem"

const OffresFournisseurs=(props)=>{

    const actualiser = ()=>{
        props.actualiser()
    }

    return(
        <div className="row ">
            <h3 className="m-2">{props.statut + ": " + props.fournisseurs.length}</h3>
            {
                props.fournisseurs.map((offre, index)=><OffreItem key={offre.idOffre} offre={offre} index={index} actualiser={actualiser} />)
            }

        </div>
    )
}

export default OffresFournisseurs