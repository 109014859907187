import { useEffect } from "react"
import OffreItem from "./OffreItem"

const OffresAcheteurs=(props)=>{

    const actualiser = ()=>{
        props.actualiser()
    }

    return(
        <div className="row">
            <h3 className="m-2">{props.statut + ": " + props.acheteurs.length}</h3>
            {
                props.acheteurs.map((offre, index)=><OffreItem key={offre.idOffre} offre={offre} index={index}  actualiser={actualiser} emails={props.emails}/>)
            }
        </div>
    )
}

export default OffresAcheteurs