import { faCamera, faPeace, faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import connexionServeur from "../Helpers/ConnexionServeur"

const CategorieItem = props=>{
    const BASE_URL = "http://rimexinter.com"
    let categorie = props.categorie
    

    const clicModification = (e)=>{
        props.ouvrir(e.currentTarget.id)
    }

    const clicModificationPhoto = (e)=>{
        props.ouvrirModalPhoto(e.currentTarget.id)
    }
    
    return(
        <div className="card m-3 p-0 shadow rounded" style={{width: 250}}>
            <img className="img-fluid card-img-top" height={200} src={BASE_URL + categorie.photo} alt={categorie.categorie}/>
            <div className="card-body ">
                <h5 className="card-title text-center">{categorie.categorie}</h5>
            </div>
            <div className="card-footer text-muted">
                <div className="row">
                    <div className="col text-end">
                        <button className="btn" id={props.index} onClick={clicModification}>
                            <FontAwesomeIcon icon={faPen} className="mx-auto" />
                        </button>
                    </div>
                    <div className="col">
                        <button className="btn"  id={props.index} onClick={clicModificationPhoto}>
                            <FontAwesomeIcon icon={faCamera} className="text-center" />
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default CategorieItem