import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import connexionServeur from "../Helpers/ConnexionServeur"

const ModalAjoutProduit = (props)=>{

    const [nomProduit, setNomProduit] = useState("")
    const [idSousCategorie, setIdSousCategorie] = useState(0)
    const [photoProduit, setPhotoProduit] = useState("")
    const [erreurNomProduit, setErreurNomProduit] = useState("")
    const [erreurPhotoProduit, setErreurPhotoProduit] = useState("")

    const [listeSousCategories, setListeSousCategories] = useState([])

    useEffect(() => {
        getSousCategories()
      }, [])

    const nomProduitChange = e=>{
        setNomProduit(e.target.value)
    }

    const idSousCategorieChange = e=>{
        setIdSousCategorie(e.target.value)
    }

    const photoProduitChange = e=>{
        setPhotoProduit(e.target.files[0])
    }

    const valider=()=>{
        setErreurNomProduit("")
        setErreurPhotoProduit("")
        
        let valide = true

        if(nomProduit == "")
        {
            setErreurNomProduit("Entrez le nom")
            valide = false
        }
        if(photoProduit == "")
        {
            setErreurPhotoProduit("Choississez une photo")
            valide = false
        }

        if(valide == true)
            envoyerDonnees()
    }

    const getSousCategories = async ()=>{
        let response = await connexionServeur("sous_categories/getSousCategories", [])
        let data = await response.json()
        setListeSousCategories(data.donnees)
        setIdSousCategorie(listeSousCategories[0].idSousCategorie)
    }

    const envoyerDonnees =async ()=>{
        let donnees = new FormData()
        donnees.append("produit", nomProduit)
        donnees.append("idSousCategorie", idSousCategorie)
        donnees.append("photo", photoProduit)
        
        let response = await connexionServeur("produits/addProduit", donnees)
        let data = await response.json()

        if(data.resultat == false)
            alert(data.message)
        else
        {
            alert(data.message)
            props.fermer(true)
        }
    }

    const ouverture = ()=>{
        setNomProduit("")
        setPhotoProduit("")
        setErreurNomProduit("")
        setErreurPhotoProduit("")
        setIdSousCategorie(listeSousCategories[0].idSousCategorie)
        props.fermer()
    }

    
    
    return (
        <Modal  show = {props.afficher}
                backdrop="static"
                keyboard={false}
                centered
                onHide={ouverture}
            >
            <Modal.Header closeButton>
                <Modal.Title>Ajout de produit</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Nom du produit</Form.Label>
                        <Form.Control placeholder="" type="input" onChange={nomProduitChange} />
                        <Form.Label className="text-danger">{erreurNomProduit}</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Indiquez la sous-catégorie</Form.Label>
                        <Form.Select value={idSousCategorie} onChange={idSousCategorieChange}>
                            {
                                listeSousCategories.map((sousCategorie)=>{
                                    return <option key={sousCategorie.idSousCategorie} value={sousCategorie.idSousCategorie}>{sousCategorie.sous_categorie}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Sélectionnez une image</Form.Label>
                        <Form.Control placeholder="" type="file" onChange={photoProduitChange} />
                        <Form.Label className="text-danger">{erreurPhotoProduit}</Form.Label>
                    </Form.Group>
                </Form>
                
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={ouverture}>Annuler</Button>
                <Button variant="primary" onClick={valider}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalAjoutProduit