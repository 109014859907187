import { faBars, faRightFromBracket } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Outlet, useNavigate } from "react-router-dom"
import Categories from "../Composants/Categories"
import Clients from "../Composants/Clients"
import Offres from "../Composants/Offres"
import Produits from "../Composants/Produits"
import SideMenu from "../Composants/SideMenu"
import SousCategories from "../Composants/SousCategories"

const DashBoard = ()=>{
    let navigate = useNavigate();

    const clicDeconnexion=(e)=>{
        e.preventDefault()

        let reponse = window.confirm("Voulez-vous vous deconnecté ?")

        if(reponse)
        {
            navigate("/")
        }
            
    }

    return(
        <div className="container-fluid p-0 m-0">
            <div className="row flex-nowrap min-vh-100 m-0 p-0">
                <SideMenu />
                
                <div className="col p-0 ">
                    <nav className="navbar navbar-dark primaire  p-0 m-0 sticky-top">
                        <a className="navbar-brand ms-auto " href="#" onClick={clicDeconnexion}>
                            <FontAwesomeIcon icon={faRightFromBracket} className="aqua"/>
                        </a>
                    </nav>
                    
                    <div className="p-3">
                            
                        <Outlet />
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashBoard