import ClientItem from "./ClientItem"
import {CSVLink} from "react-csv"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel } from "@fortawesome/free-solid-svg-icons"

const Acheteurs=(props)=>
{
    let headers = [
        { label: "Numéro d'entreprise", key: 'numeroEntreprise' },
        { label: "Nom de l'entreprise", key: 'nomEntreprise' },
        { label: "Personne ressource", key: 'nomComplet' },
        { label: "Numéro de téléphone", key: 'telephone' },
        { label: "E-Mail", key: 'email' },
    ]

    return(
        <div className="row">
            <div className="text-end text-primary">
                <CSVLink data={props.listeAcheteurs} headers={headers} separator=";" filename="Liste des acheteurs" className="btn">
                    <FontAwesomeIcon icon={faFileExcel} size="2x" className="text-success" />
                </CSVLink>
            </div>
            {props.listeAcheteurs.map((client, index)=><ClientItem key={client.idClient} client={client} index={index} /*ouvrir={openModalUpdateCategorie}*/ />)}
        </div>
    )
}

export default Acheteurs