import { faAt, faBuilding, faCheck, faCircleInfo, faClock, faEllipsisVertical, faEnvelope, faHashtag, faInfo, faJoint, faPaperclip, faPerson, faPhone, faUser, faUserAlt, faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { OverlayTrigger, Popover } from "react-bootstrap"
import connexionServeur from "../Helpers/ConnexionServeur"

const OffreItem = (props)=>{

    let emails = props.emails
    let afficher = ""

    let couleurBadge = "bg-primary"
    let piece = ""

    if(props.offre.piece =="")
        piece = " d-none"
    
    if(props.offre.typeOffre =="achat")
        couleurBadge = " bg-success"

    if(props.emails == undefined)
        afficher = " d-none"

    const updateStatut = async (e)=>{
        e.preventDefault()

        let donnees = new FormData()
        donnees.append("statut", e.currentTarget.id)
        donnees.append("idOffre", props.offre.idOffre)

        let response = await connexionServeur("offres/updateStatut", donnees)
        let data = await response.json()

        if(data.resultat == true)
            props.actualiser()
        else
            alert(data.message)
            
    }
    
    const popover = (
        <Popover id="popover-basic" style={{maxWidth:500}}>
            <Popover.Body>
                <div className="card border-0">
                    <div className="card-header  bg-black text-white">
                        <h5 className="card-title"> {props.offre.nomEntreprise}</h5>
                        <p className="card-subtitle">{props.offre.numeroEntreprise}</p>
                    </div>
                    <div className="clard-body p-3 border-1">
                        <h5><FontAwesomeIcon icon={faUser} /> {props.offre.nom }</h5>
                        <h5><FontAwesomeIcon icon={faPhone} />   {props.offre.telephone}</h5>
                        <h5><FontAwesomeIcon icon={faAt} />   {props.offre.email}</h5>
                    </div>
                </div>
            </Popover.Body>
        </Popover>
        );
        

    return(
        <div className="col-12 my-2">
            <div className="card" >
                <div className="card-body primaire ">
                    <div className="row">
                        <div className="col-11">
                            <h6 className="card-subtitle text-white">{props.offre.statut}</h6>
                            <h5 className="card-title aqua" >
                                {props.offre.nomEntreprise}
                                <span>
                                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                        <FontAwesomeIcon icon={faCircleInfo} className="ms-3"/>
                                    </OverlayTrigger>
                                </span>
                                
                            </h5>
                            <h6 className="card-subtitle mb-2 aqua">{props.offre.dateOffre}</h6>
                        </div>
                        <div className="col-1 text-center p-0">
                            <div className="dropdown">
                                <button className="btn bg-transparent" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FontAwesomeIcon icon={faEllipsisVertical} className="aqua" /> 
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" id="En traitement" href="#" onClick={updateStatut}><FontAwesomeIcon icon={faClock} className="text-warning me-2"/>En traitement</a></li>
                                    <li><a className="dropdown-item" id="Acceptée" href="#"  onClick={updateStatut}><FontAwesomeIcon icon={faCheck} className="text-success me-2"/>Accepter l'offre</a></li>
                                    <li><a className="dropdown-item" id="Refusée"  href="#"  onClick={updateStatut}><FontAwesomeIcon icon={faX} className="text-danger me-2"/>Refuser l'offre</a></li>
                                    <li><hr className={"dropdown-divider" + afficher} /></li>
                                    <li><a className={"dropdown-item" + afficher} id="Refusée"  href={"mailTo:" + emails}  ><FontAwesomeIcon icon={faEnvelope} className="text-primary me-2"/> Diffuser l'offre</a></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="card-body">
                    <h4 className="card-subtitle mb-2 text-muted">
                        {/* <span class={"badge me-3 " + couleurBadge}>{props.offre.typeOffre}</span>  */}
                        {props.offre.titre}
                    </h4>
                    <p className="card-text">{props.offre.texte}</p>
                </div>
                <ul className={"list-group list-group-flush" + piece}>
                    <li className="list-group-item">
                        <a href="#">
                            <FontAwesomeIcon icon={faPaperclip} className="me-3"/>
                            Pièce jointe
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default OffreItem