import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import connexionServeur from "../Helpers/ConnexionServeur"

const ModalUpdateProduit = (props)=>{

    const [nomProduit, setNomProduit] = useState("")
    const [idProduit, setIdProduit] = useState(0)
    const [idSousCategorie, setIdSousCategorie] = useState(0)
    const [erreurNomProduit, setErreurNomProduit] = useState("")

    const [listeSousCategories, setListeSousCategories] = useState([])

    useEffect(() => {
        if(props.currentProduit != null)
        {
            setNomProduit(props.currentProduit.produit)
            setIdProduit(props.currentProduit.idProduit)
            setIdSousCategorie(props.currentProduit.idSousCategorie)
        }
            
    }, [props])
    
    useEffect(() => {
        getSousCategories()
      }, [])

    const nomProduitChange = e=>{
        setNomProduit(e.target.value)
    }

    const idSousCategorieChange = e=>{
        setIdSousCategorie(e.target.value)
    }


    const valider=()=>{
        setErreurNomProduit("")
        
        let valide = true

        if(nomProduit == "")
        {
            setErreurNomProduit("Entrez le nom")
            valide = false
        }

        if(valide == true)
            envoyerDonnees()
    }

    const getSousCategories = async ()=>{
        let response = await connexionServeur("sous_categories/getSousCategories", [])
        let data = await response.json()
        setListeSousCategories(data.donnees)
        setIdSousCategorie(listeSousCategories[0].idSousCategorie)
    }

    const envoyerDonnees =async ()=>{
        let donnees = new FormData()
        donnees.append("produit", nomProduit)
        donnees.append("idSousCategorie", idSousCategorie)
        donnees.append("idProduit", idProduit)
        
        let response = await connexionServeur("produits/updateProduit", donnees)
        let data = await response.json()

        if(data.resultat == false)
            alert(data.message)
        else
        {
            alert(data.message)
            props.fermer(true)
        }
    }

    const reset = ()=>{
        setNomProduit("")
        setErreurNomProduit("")
        setIdSousCategorie(listeSousCategories[0].idSousCategorie)
        props.fermer()
    }

    
    
    return (
        <Modal  show = {props.afficher}
                backdrop="static"
                keyboard={false}
                centered
                onHide={reset}
            >
            <Modal.Header closeButton>
                <Modal.Title>Modification de sous-catégorie</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Nom du produit</Form.Label>
                        <Form.Control placeholder="" type="input" onChange={nomProduitChange} value={nomProduit}/>
                        <Form.Label className="text-danger">{erreurNomProduit}</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Indiquez la catégorie</Form.Label>
                        <Form.Select value={idSousCategorie} onChange={idSousCategorieChange}>
                            {
                                listeSousCategories.map((sousCategorie)=>{
                                    return <option key={sousCategorie.idSousCategorie} value={sousCategorie.idSousCategorie}>{sousCategorie.sous_categorie}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Form>
                
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={reset}>Annuler</Button>
                <Button variant="primary" onClick={valider}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalUpdateProduit