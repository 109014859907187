import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import connexionServeur from "../Helpers/ConnexionServeur";
import OffresAcheteurs from "./OffresAcheteurs";
import ClientItem from "./ClientItem";
import OffresFournisseurs from "./OffresFournisseurs";
import OffreItem from "./OffreItem";

const Offres = ()=>{
    const [listeFournisseurs, setListeFournisseurs] = useState([])
    const [listeAcheteurs, setListeAcheteurs] = useState([])
    const [emails, setEmails] = useState("")

    const [statut, setStatut] = useState("Nouveau")
    const [statutComplet, setStatutComplet] = useState("Nouvelles offres")
    const [fournisseur, setFournisseur] = useState(true)
    const [refresh , setRefresh] = useState(false)

    useEffect(() => {
        getOffresByStatut();
    },[statut, refresh] )

    useEffect(()=>{
        getListeEmailFournisseurs()
    },[])

    const getListeEmailFournisseurs = async ()=>{
        let response = await connexionServeur("clients/getListeEmailFournisseurs", null)
        let data = await response.json()

        if(data.resultat == true)
        {
            let s = ""
            for(let i = 0 ; i<data.donnees.length ; i++)
            {
                s+=data.donnees[i].email
                if(i < data.donnees.length - 1)
                    s+=","
            }
            setEmails(s)
        }
        else
            alert(data.message)
    }

    const tabClic = (e)=>{
        e.preventDefault()
        if(e.currentTarget.id == "fournisseurs")
            setFournisseur(true)
        else
            setFournisseur(false)
    }

    const actualiser = ()=>{
        setRefresh(!refresh)
    }

    const getOffresByStatut =async ()=>{
        setListeAcheteurs([])
        setListeFournisseurs([])

        let donnees = new FormData()
        donnees.append("statut", statut)
        let response = await connexionServeur("offres/getOffresByStatut", donnees)
        let data = await response.json()
        if(data.resultat == true)
        {
            setListeAcheteurs(data.achats)
            setListeFournisseurs(data.ventes)
        }
        
    }

    const statutChange = (e)=>{
        setStatut(e.target.value)
        setStatutComplet(e.target.options[e.target.selectedIndex].text)
    }

    return(
        <div >
            <div className="container">
                <div className="row ">
                    <div className="col-md-6 col-12">
                        <h1 className="">Offres des clients</h1>
                    </div>
                    <div className="col-md-6 col-12 align-self-center text-end">
                        <select className="form-select" aria-label="Default select example" value={statut} onChange={statutChange}>
                            <option value="">Toutes les offres</option>
                            <option value="Nouveau">Nouvelles offres</option>
                            <option value="En traitement">En traitement</option>
                            <option value="Acceptée">Offres acceptées</option>
                            <option value="Refusée">Offres refusées</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="container mt-4 p-3">
                <div className="card">
                    <div className="card-header">
                        <ul className="nav nav-tabs card-header-tabs">
                            <li className="nav-item">
                                <a className={fournisseur ? "nav-link active tabActif " : "nav-link tabInactif "} id="fournisseurs" onClick={tabClic} aria-current="true" href="#">Fournisseurs</a>
                            </li>
                            <li className="nav-item">
                                <a className={fournisseur ? "nav-link tabInactif " : "nav-link active tabActif "} id="acheteurs" onClick={tabClic}  href="#">Acheteurs</a>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body">
                        {fournisseur ? <OffresFournisseurs fournisseurs={listeFournisseurs} statut={statutComplet} actualiser={actualiser}/> : <OffresAcheteurs acheteurs={listeAcheteurs}  statut={statutComplet} actualiser={actualiser} emails={emails}/>}
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Offres