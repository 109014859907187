import { faCamera, faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import connexionServeur from "../Helpers/ConnexionServeur"

const ProduitItem = props=>{
    const BASE_URL = "http://rimexinter.com"
    let produit = props.produit

    const clicModification = (e)=>{
        props.ouvrir(e.currentTarget.id)
    }

    const clicModificationPhoto = (e)=>{
        props.ouvrirModalPhoto(e.currentTarget.id)
    }
    
    return(
        <div className="card m-3 p-0 shadow rounded" style={{width: 250}}>
            <div className="card-header text-center">
                <h6 className="card-subtitle my-2 text-muted">{produit.categorie}</h6>
            </div>
            <img className="img-fluid card-img-top" height={100} src={BASE_URL + produit.photo} alt={produit.produit}/>
            <div className="card-body text-center">
                <h5 className="card-title ">{produit.produit}</h5>                
                <h6 className="card-subtitle my-2 text-muted">{produit.sous_categorie}</h6>
            </div>
            <div className="card-footer text-muted">
                <div className="row">
                    <div className="col text-end">
                        <button className="btn" id={props.index} onClick={clicModification}>
                            <FontAwesomeIcon icon={faPen} className="mx-auto" />
                        </button>
                    </div>
                    <div className="col">
                        <button className="btn"  id={props.index} onClick={clicModificationPhoto}>
                            <FontAwesomeIcon icon={faCamera} className="text-center" />
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default ProduitItem