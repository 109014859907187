import { faCamera, faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import connexionServeur from "../Helpers/ConnexionServeur"

const SousCategorieItem = props=>{
    const BASE_URL = "http://rimexinter.com"
    let sousCategorie = props.sousCategorie

    const clicModification = (e)=>{
        props.ouvrir(e.currentTarget.id)
    }

    const clicModificationPhoto = (e)=>{
        props.ouvrirModalPhoto(e.currentTarget.id)
    }
    
    return(
        <div className="card m-3 p-0 shadow rounded" style={{width: 250}}>
            <div className="card-header text-center">
                <h6 className="card-subtitle my-2 text-muted">{sousCategorie.categorie}</h6>
            </div>
            <img className="img-fluid card-img-top" height={100} src={BASE_URL + sousCategorie.photo} alt={sousCategorie.sous_categorie}/>
            <div className="card-body text-center">
                <h6 className="card-subtitle my-2 text-muted">{sousCategorie.categorie}</h6>
                <h5 className="card-title ">{sousCategorie.sous_categorie}</h5>
            </div>
            <div className="card-footer text-muted">
                <div className="row">
                    <div className="col text-end">
                        <button className="btn" id={props.index} onClick={clicModification}>
                            <FontAwesomeIcon icon={faPen} className="mx-auto" />
                        </button>
                    </div>
                    <div className="col">
                        <button className="btn"  id={props.index} onClick={clicModificationPhoto}>
                            <FontAwesomeIcon icon={faCamera} className="text-center" />
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default SousCategorieItem