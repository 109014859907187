import { faAt, faBuilding, faMailBulk, faPerson, faPhone, faUser, faUserAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ClientItem = (props)=>{

    return(
        <div class="col-12 col-md-6 p-3">
            
                <div className="card shadow">
                    <div className="card-header bg-black text-white bordure-gauche">
                        <h4 className="card-title"> {props.client.nomEntreprise}</h4>
                        <h6 className="card-subtitle">{props.client.numeroEntreprise}</h6>
                    </div>
                    <div className="clard-body p-3 border-1">
                        <h4><FontAwesomeIcon icon={faUser} /> {props.client.contact}</h4>
                        <h4><FontAwesomeIcon icon={faPhone} />   {props.client.telephone}</h4>
                        <h4><FontAwesomeIcon icon={faAt} />   {props.client.email}</h4>
                    </div>
                </div>
            
        </div>
    )
}

export default ClientItem