import DashBoard from "./Pages/DashBoard"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import './App.css';
import Connexion from "./Pages/Connexion";
import Offres from "./Composants/Offres";
import Clients from "./Composants/Clients";
import Categories from "./Composants/Categories";
import SousCategories from "./Composants/SousCategories";
import Produits from "./Composants/Produits";
import Erreur from "./Pages/Erreurs";

function App() {
  return (
    <BrowserRouter>
      <div className="vh-100">
        <Routes>
          <Route exact path='/' element={<Connexion />} />
          <Route path='/admin' element={<DashBoard />}>
            <Route path='offres' element={<Offres />}/>
            <Route path='clients' element={<Clients />}/>
            <Route path='categories' element={<Categories />}/>
            <Route path='sous-categories' element={<SousCategories />}/>
            <Route path='produits' element={<Produits />}/>
          </Route>
          <Route path='*' element={<Erreur />} />
        </Routes>
      </div>
    </BrowserRouter>
    
  );
}

export default App;
