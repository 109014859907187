import { useState } from "react"
import { useNavigate } from "react-router-dom"

const Connexion = ()=>{
    const navigate = useNavigate()
    const [pseudo , setPseudo] = useState("")
    const [mdp , setMDP] = useState("")
    const [erreurMDP , setErreurMDP] = useState("")
    const [erreurPseudo , setErreurPseudo] = useState("")
    

    const pseudoChange = (e)=>{
        setPseudo(e.target.value)
    }

    const mdpChange = (e)=>{
        setMDP(e.target.value)
    }

    const clickConnexion = (e)=>{
        e.preventDefault()

        let valide = true
        setErreurMDP("")
        setErreurPseudo("")

        if(pseudo.trim() == "")
        {
            setErreurPseudo("Entrez le pseudo")
            valide=false;
        }

        if(mdp.trim() == "")
        {
            setErreurMDP("Entrez le mot de passe")
            valide=false;
        }

        if(valide == true)
            navigate("/admin/offres")
    }

    return (
        <div className="container-fluid bg-light h-100">
            <div className="row align-items-center justify-content-center h-100 p-5">
                <div className="col-12 col-md-6 shadow rounded bg-white">
                    <div className="row primaire">
                        <div className="col d-none primaire aqua d-md-inline text-center align-self-center">
                            <h1>RIMEX</h1>
                        </div>
                        <div className="col bg-white">
                            <form noValidate className="p-5 ">
                                <h1 className="text-center  d-block d-md-none">RIMEX</h1>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="utilisateur">Nom d'utilisateur</label>
                                    <input type="text" className="form-control" onChange={pseudoChange} id="utilisateur" />
                                    <p className="text-danger">{erreurPseudo}</p>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="utilisateur">Mot de passe</label>
                                    <input type="password" className="form-control" onChange={mdpChange}/>
                                    <p className="text-danger">{erreurMDP}</p>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary" onClick={clickConnexion}>Connexion</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-4 col-12 bg-white shadow rounded  ">
                    <form className="p-2 ">
                        <h1 className="text-center">RIMEX</h1>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="utilisateur">Nom d'utilisateur</label>
                            <input type="text" className="form-control" required id="utilisateur" />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="utilisateur">Mot de passe</label>
                            <input type="password" className="form-control" required/>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary" onClick={clickConnexion}>Connexion</button>
                        </div>
                    </form>
                </div> */}
            </div>
        </div>
    )
}

export default Connexion
