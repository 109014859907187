import { faEllipsisVertical, faFileExcel } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ClientItem from "./ClientItem"
import {CSVLink} from "react-csv"
const Fournisseurs=(props)=>
{

    let headers = [
        { label: "Numéro d'entreprise", key: 'numeroEntreprise' },
        { label: "Nom de l'entreprise", key: 'nomEntreprise' },
        { label: "Personne ressource", key: 'nomComplet' },
        { label: "Numéro de téléphone", key: 'telephone' },
        { label: "E-Mail", key: 'email' },
    ]
    

    return(
        <div className="row">
            <div className="text-end text-primary">
                <CSVLink data={props.listeFournisseurs} headers={headers} separator=";" filename="Liste des fournisseurs" className="btn">
                    <FontAwesomeIcon icon={faFileExcel} size="2x" className="text-success" />
                </CSVLink>
            </div>
            {props.listeFournisseurs.map((client, index)=><ClientItem key={client.idClient} client={client} index={index} /*ouvrir={openModalUpdateCategorie}*/ />)}
        </div>
    )
}

export default Fournisseurs