import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import connexionServeur from "../Helpers/ConnexionServeur"
import ModalAjoutCategorie from "../Modals/ModalAjoutCategorie"
import ModalUpdateCategorie from "../Modals/ModalUpdateCategorie"
import ModalUpdatePhotoCategorie from "../Modals/ModalUpdatePhotoCategorie"
import CategorieItem from "./CategorieItem"

const Categories = ()=>{

    const [donnees, setDonnees] = useState([])
    const [currentCategorie, setCurrentCategorie] = useState(null)
    const [modalAjoutCategorieOpen, setModalAjoutCategorieOpen] = useState(false)
    const [modalUpdateCategorieOpen, setModalUpdateCategorieOpen] = useState(false)
    const [modalUpdatePhotoCategorieOpen, setModalUpdatePhotoCategorieOpen] = useState(false)
    const [retour, setRetour] = useState(false)

    useEffect(() => {
        getCategories()
      }, [retour])

    const clicBtAjoutCategorie = (e)=>{
        setModalAjoutCategorieOpen(true)
    }

    const openModalUpdateCategorie = (index)=>{
        setCurrentCategorie(donnees[index])
        setModalUpdateCategorieOpen(true)
    }

    const openModalUpdatePhotoCategorie = (index)=>{
        setCurrentCategorie(donnees[index])
        setModalUpdatePhotoCategorieOpen(true)
    }

    const fermerModal = (success)=>{
        setModalAjoutCategorieOpen(false)
        setModalUpdateCategorieOpen(false)
        setModalUpdatePhotoCategorieOpen(false)
        if(success == true)
            setRetour(!retour)
    }

    const getCategories =async ()=>{
        let response = await connexionServeur("categories/getCategories", [])
        let data = await response.json()
        setDonnees(data.donnees)
    }

    return(
        <div className="container">
            <div className="row ">
                <div className="col">
                    <h1 className="">Catégories</h1>
                </div>
                <div className="col align-self-center text-end">
                    <button className="btn btn-success" onClick={clicBtAjoutCategorie}>
                        <FontAwesomeIcon icon={faPlus} /> <span className="d-none d-sm-inline"> Nouvelle catégorie</span>   
                    </button> 
                </div>
            </div>
        
            <div className="row row-cols-1 row-cols-md-4 g-1">
                {
                    donnees.map((categorie, index)=><CategorieItem key={categorie.idCategorie} categorie={categorie} index={index} ouvrir={openModalUpdateCategorie} ouvrirModalPhoto={openModalUpdatePhotoCategorie}/>)
                }
            </div>

            <ModalAjoutCategorie afficher={modalAjoutCategorieOpen} fermer={fermerModal} />
            <ModalUpdateCategorie currentCategorie={currentCategorie} afficher={modalUpdateCategorieOpen}  fermer={fermerModal} />
            <ModalUpdatePhotoCategorie currentCategorie={currentCategorie} afficher={modalUpdatePhotoCategorieOpen}  fermer={fermerModal} />

        </div>
    )
}

export default Categories